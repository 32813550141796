<template>
<div style="height: 100%; width: 100%; display: inline-block; position: fixed;">
  <!-- <div style="overflow: auto;">
    <p>First marker is placed at {{ withPopup.lat }}, {{ withPopup.lng }}</p>
    <p>Center is at {{ currentCenter }} and the zoom is: {{ currentZoom }}</p>
  </div> -->
  <l-map
    ref="myMap"
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    style="height: 100%"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
  >
    <l-tile-layer
      :url="url"
      :attribution="attribution"
    />
    <l-control class="example-custom-control">
      <div @click.stop.prevent="getDirection">
        Open Google Map
      </div>
    </l-control>
    <l-marker :lat-lng="center">
      <l-tooltip v-if="showTooltip" :options="{ permanent: false, interactive: true }">
        <div>
          <strong>Budi & shinta</strong> <br>
          Resepsi 01-Jan-2022 <br>
          Pukul: 19:00 - 21:00 <br>
          Gd. Purna Bhakti <br>
          jl. kecebong bening 20 no 3
        </div>
      </l-tooltip>
    </l-marker>
    <l-control
        :position="'bottomleft'"
        class="custom-control-watermark"
      >
      Sebarundangan.id
    </l-control>
  </l-map>
</div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip, LControl } from "vue2-leaflet";
import SetupMap from "../mixins/SetupMap"


export default {
name: "leafLetMap",
components: {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LControl
},
mixins: [
  SetupMap
],
data() {
  return {
  };
},
};
</script>

<style type="scss" scoped>
  .example-custom-control {
    background: #37BA85!important;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #fff;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    top: 5px;
    right: 5px;
    font-weight: bold;
    -webkit-box-shadow: 1px 1px 10px 1px #00000066;
    box-shadow: 1px 1px 10px 1px #00000066;
    &:hover,&:focus {
      background-color: #37BA85;
      coolor:#fff;
    }
  }
  .custom-control-watermark {
    font-size: 14px;
    font-weight: bolder;
    color: #37BA85;
    text-shadow: 2px 2px 5px #fff;
    padding: 5px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: #fff!important;
    bottom:5px;
    left: 5px;
    -webkit-box-shadow: 1px 1px 10px 1px #00000066;
    box-shadow: 1px 1px 10px 1px #00000066;
  }
  </style>