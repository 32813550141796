import { latLng } from "leaflet";

const config = {
  lat: -6.2293867,
  long: 106.6894316,
  position: latLng(-6.2293867, 106.6894316),
  zoom: 4,
  zoomSnap: 0.5,
  showToolip: false,
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
};
export { config }