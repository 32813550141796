<script>
import { Icon } from "leaflet";
import { config } from "../utils/Leaflet"
import { latLng } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  className: 'custom-marker',
  iconRetinaUrl: require('../assets/location-point3.png'),
  iconUrl: require('../assets/location-point3.png'),
  iconSize: [52.5,70],
  shadowUrl: null,
  // shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  // shadowAnchor: config.position,
});

export default {
  name: "SetupMap",
  data() {
    return {
      zoom: config.zoom,
      center: config.position,
      url: config.url,
      attribution: config.attribution,
      withPopup: config.position,
      withTooltip: config.position,
      currentZoom: config.zoom,
      currentCenter: config.position,
      long: 0,
      lat: 0,
      mapOptions: {
        zoomSnap: config.zoomSnap
      },
      showTooltip: config.showTooltip,
    };
  },
  watch: {
    '$route': {
      handler: function (val) {
        if (Object.keys(val.query).length) {
          this.assignParamsValue(val.query)
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    assignParamsValue(query) {
      this.center = latLng(query.lat, query.long);
      this.withPopup = latLng(query.lat, query.long);
      this.withTooltip = latLng(query.lat, query.long);
      this.currentCenter = latLng(query.lat, query.long);
      this.long = query.long;
      this.lat = query.lat;
      this.zoom = parseInt(query.z);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    getDirection() {
      const url = `https://www.google.co.id/maps/@${this.lat},${this.long},${this.zoom}z`;
      console.log(url);
      window.open(url, "_blank");
    }
  }
}
</script>